import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classes from "../tincan.module.scss";
import queryString from "query-string";
import { tincanLoginRequest } from "../../../store/auth/actions";

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
} from "store/auth/selector";

//selectors end


const TinCanLogin = ({callTinCanLogin,user,loading,error}) => {
  
  const [qrData, setQrData] = useState(`${window.location.origin}/tincan`);
  
  const parsed = queryString.parse(window.location.search);
  console.log('parsed',parsed);

  useEffect(() => {
    
    callTinCanLogin({auth: parsed.auth, actor: parsed.actor});
      

  }, []);

  

  return (
    <div className={classes.Container}>
          
         <h3>Please wait while we check your details...</h3> 
    </div>
  );
};


const mapStateToProps = createStructuredSelector({
    user: makeSelectUser(),
    loading: makeSelectLoading(),
    error: makeSelectError()
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
        callTinCanLogin: (val) => dispatch(tincanLoginRequest(val)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(TinCanLogin);

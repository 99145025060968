import React, { useState, useEffect } from "react";

import classes from "./header.module.scss";
import { withRouter, Link } from "react-router-dom";
import { selectLanguage } from "store/language/actions";

import { setMeRequest } from "store/auth/actions";
import { setSectionStepReq, setSectionReq } from "store/induction/actions";

import {
  makeSelectLanguages,
  makeSelectLoading,
  makeSelectSelectedLanguage,
} from "store/language/selector";
import { makeSelectUser } from "store/auth/selector";
import {
  makeSelectSelectedInduction,
  makeSelectCurrentRule,
  makeSelectSectionStep,
  makeSelectChapterCompletion,
} from "store/induction/selector";
import { makeSelectProgress } from "store/progress/selector";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MenuIcon from "./menu.svg";
import { useLocate } from "utils/hooks";
import { isNil, flatMapDeep, size, indexOf, isEmpty, includes } from "lodash";
import MainLogo from "./logo.svg";
import TickIcon from "./tick.svg";
import ArrowIcon from "./arrow.svg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import cn from "classnames";
import { FormattedMessage } from "react-intl";

// const MainLogo = "https://keepsafe.work/images/Keepsafe-logo.svg";

const Header = ({
  transparent,
  menu,
  setOpen,
  selectedInduction,
  history,
  currentRule,
  inductionProgress,
  setSection,
  setSectionStep,
  sectionStep,
  chapterCompletion,
}) => {
  const { previous, current, index } = useLocate(
    selectedInduction,
    history?.location?.pathname
  );

  const isBackButtonVisible = current?.back_button_visible === true;

  const isRulesPage = window.location.pathname === "/rules";

  const total = selectedInduction?.data?.pages?.length;

  const currentIndex = index && index + 1;

  const next = index && index + 2;

  
  const [completedRules, setCompletedRules] = useState();
  const ruleList = selectedInduction?.data?.pages
    .find((item) => item?.type === "rules")
    ?.data?.rules.map((rule, i) => {
      return {
        ...rule,
        progressId: index + 1 + i + 1,
      };
    });

  useEffect(() => {
    if (inductionProgress) {
      const list = selectedInduction?.data?.pages
        .find((item) => item?.type === "rules")
        ?.data?.rules.map((rule, i) => {
          return {
            ...rule,
            progressId: rule?.id ? rule?.id : index + 1 + i + 1,
            completed:
              Number(inductionProgress?.section) > rule?.id
                ? true
                : false ||
                  (inductionProgress?.section === rule?.id &&
                    inductionProgress?.progress === 100)
                ? true
                : false,
          };
        });
      const completedItems = list?.filter((item) => item.completed === true);
      setCompletedRules(completedItems);
    }
  }, [inductionProgress, selectedInduction]);

  const ruleSize = !isNil(ruleList) && !isEmpty(ruleList) && ruleList?.length;

  //const progress = () => index && Number(currentIndex / (total + ruleSize -1)) * 100;

  function progress()
  {
    const ruleId = selectedInduction?.data?.pages
    .find((item) => item?.type === "rules")?.id;

    const currentId = selectedInduction?.data?.pages[index]?.id;

    if(parseInt(currentId) > parseInt(ruleId))
      {
          return index && Number(currentIndex / total) * 100;
      }
      else
      {
        return index && Number(currentIndex / (total + ruleSize -1)) * 100;
      }


  }
  



  const rulesProgress = () => {
    const sectionList = flatMapDeep(ruleList?.map((item) => item.sections));

    const currentSectionIndex = indexOf(sectionList, currentRule?.section, 0);

    const totalSectionLength = size(sectionList);

    const rulesTotalProgress =
      index && Number(next / total) * 100 - Number(currentIndex / total) * 100;

    const currentRuleProgress =
      (rulesTotalProgress / totalSectionLength) * (currentSectionIndex + 1);

    return progress() + currentRuleProgress;
  };

  const headerProgress =
    isRulesPage && !isNil(currentRule?.rule) ? rulesProgress() : progress();

  return (
    <div
      style={{ background: transparent && "transparent", position: "relative" }}
      className={classes.headerWrapper}
    >
      <div className={classes.headerContainer}>
        <div className={classes.section1}>
          {isBackButtonVisible && (
            <img
              onClick={() =>
                history.push(isNil(previous) ? "/site-selector" : previous?.url)
              }
              alt={"arrow icon"}
              className={classes.arrowIcon}
              src={ArrowIcon}
            />
          )}
          {window.location.pathname === "/rules" && (
            <>
              {isNil(currentRule?.rule) || chapterCompletion ? (
                <Popup
                  trigger={(open) => (
                    <div
                      className={cn(
                        classes.completedWrapper,
                        open && classes.openBg
                      )}
                    >
                      <div className={classes.completedSection}>
                        <img
                          className={classes.tickIcon}
                          alt="tick icon"
                          src={TickIcon}
                        />
                        <span className={classes.countText}>
                          {completedRules?.length}/{ruleSize}
                        </span>
                      </div>
                      <span className={classes.completedText}>
                        <FormattedMessage
                          defaultMessage={"COMPLETED"}
                          id="header.progress.completed"
                        />
                      </span>
                    </div>
                  )}
                  on={["click"]}
                  position="bottom left"
                  closeOnDocumentClick
                  closeOnEscape
                >
                  <span>
                    <FormattedMessage
                      values={{
                        total: ruleSize,
                        completed: completedRules?.length,
                      }}
                      defaultMessage={
                        "You've completed {total} out of {completed} chapters required you enter the site."
                      }
                      id="header.progress.message"
                    />{" "}
                    {completedRules?.length === ruleSize && (
                      <FormattedMessage
                        defaultMessage={"Well Done!"}
                        id="header.progress.success"
                      />
                    )}
                  </span>
                </Popup>
              ) : sectionStep === 1 ? (
                <img
                  onClick={() => {
                    setSection(false);
                    setSectionStep(1);
                  }}
                  alt={"arrow icon"}
                  className={classes.arrowIcon}
                  src={ArrowIcon}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <Link to="/">
          <img alt={"logo"} className={classes.headerLogo} src={MainLogo} />
        </Link>
        <div className={classes.menuIconWrapper}>
          {menu && (
            <img
              alt={"menu icon"}
              onClick={() => setOpen(true)}
              src={MenuIcon}
              className={classes.menuIcon}
            />
          )}
        </div>
      </div>

      <div className={classes.progressContainer}>
        {!isNil(index) && (
          <div className={classes.progressWrapper}>
            <div
              className={headerProgress?.toFixed(0) >=99 ? classes.progressFull :classes.progress}
              style={{
                width: `${
                  !isNil(headerProgress) ? headerProgress?.toFixed(0) : 0
                }%`,
              }}
            />
          </div>
        )}
        {!isNil(headerProgress) &&
          headerProgress !== 0 &&
          !isNaN(headerProgress) && (
            <div
              style={{
                left:
                  headerProgress === 100
                    ? `${headerProgress - 10}%`
                    : `${headerProgress - 5}%`,
              }}
              className={classes.headerPercentageBox}
            >
              <span>{headerProgress.toFixed(0)}%</span>
            </div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  loading: makeSelectLoading(),
  selectedLanguage: makeSelectSelectedLanguage(),
  user: makeSelectUser(),
  selectedInduction: makeSelectSelectedInduction(),
  currentRule: makeSelectCurrentRule(),
  inductionProgress: makeSelectProgress(),
  sectionStep: makeSelectSectionStep(),
  chapterCompletion: makeSelectChapterCompletion(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectLanguage: (val) => dispatch(selectLanguage(val)),
    setMe: (val) => dispatch(setMeRequest(val)),
    setSection: (val) => dispatch(setSectionReq({ value: val })),
    setSectionStep: (val) => dispatch(setSectionStepReq({ value: val })),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

import React from "react";
import classes from "./input.module.scss";
import Select from "react-select";
import IconComponent from "components/UI/IconComponent";
import { Tooltip } from "react-tooltip";
import cn from "classnames";

const Input = (props) => {
  return (
    <>
      {props.type === "select" ? (
        <div
          className={classes.Container}
          style={{
            flexDirection: props.order === "column" && "column",
            alignItems: props.order === "column" && "flex-start",
          }}
        >
          <div
            className={cn(
              classes.labelWrapper,
              props.order === "column" && "w-full"
            )}
          >
            <label className={classes.BlockLabel}>{props.label}</label>
            {props.hasTooltip && (
              <a data-tooltip-id={props.name}>
                <IconComponent
                  icon="help_outline"
                  size="25px"
                  color="#6FCF97"
                  className={classes.tooltipIcon}
                />
              </a>
            )}
          </div>
          <div className={classes.inputContainer}>
            <Select
              isMulti={props.isMulti}
              styles={{
                control: () => ({
                  width: "100%",
                  height:
                    props.isMulti || props.order === "column" ? "auto" : 44,
                  minHeight: 44,
                  display: "flex",
                  background: "#f8f8f8",
                  color: "#40507E",
                  border: props.error
                    ? "1px solid #E26C82"
                    : `1px solid #e0e0e0`,
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontWeight: "bold",
                }),
                container: () => ({
                  width: props.order === "column" ? "100%" : "100%",
                  height:
                    props.isMulti || props.order === "column" ? "auto" : 44,
                  position: "relative",
                  boxSizing: "border-box",
                  marginBottom: props.order === "column" ? 0 : 32,
                  marginTop:
                    props.order === "column"
                      ? 0
                      : props.marginTop
                      ? props.marginTop
                      : 32,
                }),
                placeholder: () => ({
                  color: "#a5b4c9",
                  fontFamily: "Open Sans",
                  marginLeft: 5,
                  fontWeight: "normal",
                }),
                singleValue: () => ({
                  color: "#40507e",
                  marginLeft: 5,
                }),
                input: () => ({
                  padding: "unset",
                  margin: "unset",
                }),
                multiValue: () => ({
                  background:
                    " linear-gradient(91.05deg, #03ce91 0.16%, #36bc94 100%)",
                  borderRadius: "6px",
                  fontSize: "14px",
                  color: "white !important",
                  fontFamily: "Open Sans",
                  display: "flex",
                  margin: "4px",
                  padding: "5px",
                  minWidth: 0,
                  boxSizing: "border-box",
                  opacity: "0.9",
                  div: { color: "white" },
                }),
              }}
              onChange={props.onChange}
              tabSelectsValue={props.tabSelectsValue}
              value={props.value}
              placeholder={props.placeholder}
              options={props.options}
            />
            {props.tooltipText && (
              <Tooltip
                className="react-tooltip-keepsafe"
                id={props.name}
                place="left"
                type="dark"
                effect="solid"
              >
                <span>{props.tooltipText}</span>
              </Tooltip>
            )}
            {props.error && (
              <span className={classes.selectErrorMessage}>
                {props.error && typeof props.error === "string"
                  ? props.error
                  : "Please provide valid information."}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div
          className={classes.Container}
          style={{
            flexDirection: props.order === "column" && "column",
            alignItems: props.order === "column" && "flex-start",
          }}
        >
          <div
            className={cn(
              classes.labelWrapper,
              props.order === "column" && "w-full"
            )}
          >
            <label
              style={{
                marginLeft: props.order === "column" && "10px",
                width: props.order === "column" && "100%",
              }}
              className={classes.BlockLabel}
            >
              {props.label}
            </label>
            {props.hasTooltip && (
              <a data-tooltip-id={props.name}>
                <IconComponent
                  icon="help_outline"
                  size="25px"
                  color="#6FCF97"
                  className={classes.tooltipIcon}
                />
              </a>
            )}
          </div>
          <div className={classes.inputContainer}>
            {props.type === "textarea" ? (
              <textarea
                style={{
                  width: props.order === "column" && "100%",
                  height: props?.height ? props.height : "100px"
                }}
                className={`${classes.BlockInput} ${
                  props.error && classes.error
                }`}
                type={props.type}
                placeholder={props.placeholder}
                onChange={props.onChange}
                name={props?.name}
                value={props.value}
                spellCheck="false"
                autoComplete="off"
                maxLength={props.characterLimit}
              >{props.value}</textarea>
             ):
             (
                <input
                style={{
                  width: props.order === "column" && "100%",
                }}
                className={`${classes.BlockInput} ${
                  props.error && classes.error
                }`}
                type={props.type}
                placeholder={props.placeholder}
                onChange={props.onChange}
                name={props?.name}
                value={props.value}
                spellCheck="false"
                autoComplete="off"
                maxLength={props.characterLimit}
              />
             )
            }

            {props.characterLimit && props.value && (
              <span className={classes.characterLimitMessage}>
                {props.characterLimit &&
                  `Character limit ${props?.value?.length} of ${props?.characterLimit}`}
              </span>
            )}
            {props.error && (
              <span className={classes.errorMessage}>
                {props.error && typeof props.error === "string"
                  ? props.error
                  : "Please provide valid information."}
              </span>
            )}
            {props.tooltipText && (
              <Tooltip
                className="react-tooltip-keepsafe"
                id={props.name}
                place="left"
                type="dark"
                effect="solid"
              >
                <span>{props.tooltipText}</span>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Input;

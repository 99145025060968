import React, { useState, useEffect } from "react";
import classes from "./drawer.module.scss";
import { connect } from "react-redux";
import { makeSelectProgress } from "store/progress/selector";
import { makeSelectCheckInStatus } from "store/auth/selector";
import {
  makeSelectSelectedInduction,
  makeSelectCurrentRule,
} from "store/induction/selector";

import { setSelectedStep } from "store/steps/actions";
import {
  getAssesmentRequest,
  setAssesmentRequest,
  createAssesmentRequest,
  resetAssesmentResult,
} from "store/assesment/actions";
import { setProgressRequest, getProgressRequest } from "store/progress/actions";
import { withRouter } from "react-router-dom";

import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";

import { msToTime } from "utils/helper";
import { isNil, includes } from "lodash";
import DownArrow from "./down-arrow.svg";
import CloseIcon from "./close-arrow.svg";
import ExitIcon from "./exit.svg";
import cn from "classnames";

const DrawerMenu = ({
  history,
  checkInStatus,
  setOpen,
  open,
  selectedInduction,
  index,
  currentRule,
  progressData,
}) => {
  const menuItems = selectedInduction?.data?.pages?.filter(
    (item) => item?.show_in_menu === true
  );

  const staticPages = selectedInduction?.data?.static?.filter(
    (item) => item?.show_in_menu === true
  );

  const [ruleSet, setSelectedRuleSet] = useState([]);

  const rules = ruleSet?.filter((item) => item?.show_in_menu === true);

  const [accordion, setAccordion] = useState({
    rules: true,
  });

  useEffect(() => {
    if (selectedInduction) {
      const selectInduction = selectedInduction?.data?.pages
        .find((item) => item?.type === "rules")
        ?.data?.rules.map((rule, i) => {
          return { ...rule, progressId: rule?.id ? rule?.id : index + 1 + i + 1 };
        });

      setSelectedRuleSet(selectInduction);
    }
  }, [selectedInduction]);

  const [time, setTime] = useState(null);
  const [timeDiff, setTimeDiff] = useState(null);

  useEffect(() => {
    const counter = () => {
      setTimeDiff(timeDiff + 1000);
      setTime(msToTime(timeDiff + 1000));
    };
    const id = setInterval(counter, 1000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    if (!isNil(checkInStatus)) {
      var date = new Date(checkInStatus.check_in);
      var now = new Date();

      const diffTime = Math.abs(date - now);
      setTimeDiff(diffTime);
      setTime(msToTime(diffTime));
    }
  }, []);

  return (
    <React.Fragment>
      <div
        className={open ? classes.drawerContainerOpen : classes.drawerContainer}
      >
        <div className={cn(classes.drawerMenu)}>
          <div className={classes.drawerHeader}>
            <span className={classes.menuHeader}>
              <FormattedMessage
                defaultMessage={"NAVIGATION"}
                id={"navigation.title"}
              />
            </span>
            <img
              alt={"Close Icon"}
              src={CloseIcon}
              onClick={() => setOpen(false)}
            />
          </div>
          <div className={classes.drawerContent}>
            {checkInStatus && (
              <>
                <button
                  className={classes.checkoutButton}
                  onClick={() => {
                    history.push("/check-out");
                  }}
                >
                  <img alt={"Exit Icon"} src={ExitIcon} />
                  <span>
                    <FormattedMessage
                      id={`checkout.footer.text`}
                      defaultMessage={"Check-out "}
                    />
                    &nbsp;• {time}
                  </span>
                </button>
              </>
            )}
            {menuItems?.map((item, i) => (
              <React.Fragment key={`drawer item ${i}`}>
                {item.type === "rules" ? (
                  <>
                    <div
                      className={classes.accordionHeader}
                      onClick={() => {
                        setOpen(false);
                        /*
                        setAccordion({
                          rules: !accordion.rules,
                          presentation: false,
                        });
                        */

                        //history.push(`/rules`);
                        if(window)
                           window.location = '/rules';
                        else
                           history.push(`/rules`);  
                        
                        document.body.style.overflow = "unset";
                        

                      }}
                    >
                      <span
                        className={cn(
                          window.location.pathname === "/rules" &&
                            isNil(currentRule?.rule) &&
                            classes.selectedDrawerItem
                        )}
                      >
                        <FormattedMessage
                          id={"navigation.rules"}
                          defaultMessage={"HSE Rules"}
                        />
                      </span>
                      <div>
                        <img
                          alt={"Down Arrow"}
                          src={DownArrow}
                          className={
                            accordion.rules
                              ? classes.downArrow
                              : classes.downArrowClosed
                          }
                        />
                      </div>
                    </div>
                    {accordion.rules && (
                      <div className={classes.accordionContent}>
                        {rules?.map((item, i) => (
                          <span
                            key={item.title}
                            onClick={() => {
                              if (
                                progressData?.section >= item.progressId ||
                                i === 0 ||
                                (progressData?.progress === 100 &&
                                  progressData.section + 1 === item.progressId)
                              ) {
                                document.body.style.overflow = "unset";
                                history.push(`/rules?id=${i}&step_id=1`);
                                setOpen(false);
                              }
                            }}
                            className={cn(
                              window.location.pathname === "/rules" &&
                                !isNil(item?.sections) &&
                                !isNil(currentRule?.section) &&
                                includes(
                                  item?.sections,
                                  currentRule?.section
                                ) &&
                                classes.selectedDrawerItem,
                              classes.drawerItem,
                              classes.disabled,
                              (progressData?.section >= item.progressId ||
                                i === 0 ||
                                (progressData?.progress === 100 &&
                                  progressData.section + 1 ===
                                    item.progressId)) &&
                                classes.active
                            )}
                          >
                            <FormattedMessage
                              id={item.title_id}
                              defaultMessage={item.title}
                            />
                          </span>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <span
                    className={cn(
                      selectedInduction?.data?.pages[index]?.url === item.url &&
                      classes.selectedDrawerItem,
                      classes.drawerItem,
                      classes.disabled,
                      (progressData?.section >= item.id && classes.active)
                    )}
                    onClick={() => {
                        if(selectedInduction?.data?.pages[index]?.url === item.url)
                           history.push(item.url)
                      }

                    }
                  >
                    <FormattedMessage
                      id={item.title_id}
                      defaultMessage={item.title}
                    />
                  </span>
                )}
              </React.Fragment>
            ))}

            {staticPages?.map((item, i) => (
              <span
                key={item.title}
                className={classes.drawerItem}
                // onClick={() => history.push(item.url)}
              >
                <FormattedMessage
                  id={item.title_id}
                  defaultMessage={item.title}
                />
              </span>
            ))}

            <span
              className={classes.drawerItem}
              onClick={() => {
                document.body.style.overflow = "unset";
                history.push("/logout");
              }}
            >
              <FormattedMessage
                id={"navigation.logout"}
                defaultMessage={"Logout"}
              />
            </span>
          </div>
        </div>
      </div>
      <div
        className={open ? classes.overlay : classes.overlayHidden}
        onClick={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  checkInStatus: makeSelectCheckInStatus(),
  selectedInduction: makeSelectSelectedInduction(),
  currentRule: makeSelectCurrentRule(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (val) => dispatch(setSelectedStep(val)),
    getAssesment: (val) => dispatch(getAssesmentRequest(val)),
    setAssesmentQuestion: (val) => dispatch(setAssesmentRequest(val)),
    createNewAssesment: (val) => dispatch(createAssesmentRequest(val)),
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgressReq: (val) => dispatch(setProgressRequest(val)),
    resetCurrentResult: (val) => dispatch(resetAssesmentResult(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrawerMenu)
);

import React, { useState, useEffect, useMemo } from "react";

import { connect } from "react-redux";
import classes from "./induction.module.scss";
import { createStructuredSelector } from "reselect";

import Card from "components/UI/card";
import Heading from "components/UI/heading";
import Section from "components/UI/section";
import Footer from "components/UI/footer";
import Button from "components/UI/button";

//action start
import {
  getAssesmentRequest,
  setAssesmentRequest,
  createAssesmentRequest,
  resetAssesmentResult,
} from "store/assesment/actions";
import { setProgressRequest, getProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";
import {
  setCurrentRule,
  setSectionReq,
  setSectionStepReq,
} from "store/induction/actions";

//action end

//selectors start
import {
  makeSelectAssesmentResult,
  makeSelectAssesmentTryId,
  makeSelectAssesment,
  makeSelectLoading,
  makeSelectError,
} from "store/assesment/selector";
import { makeSelectProgress } from "store/progress/selector";
import {
  makeSelectSelectedInduction,
  makeSelectSection,
  makeSelectSectionStep,
} from "store/induction/selector";

import { makeSelectStep } from "store/steps/selector";
//selectors end

import { saveProgress, useLocate } from "utils/hooks";
import queryString from "query-string";
import { filter, isEmpty, isNil } from "lodash";
import { delay } from "utils/helper";

const Induction = ({
  progressData,
  setProgressReq,
  induction,
  history,
  getProgress,
  setCurrentRuleReq,
  section,
  setSection,
  sectionStep,
  setSectionStep,
  isPreview = false,
  pageData,
}) => {
  var { current, next, index } = useLocate(
    induction,
    history.location.pathname
  );

  var selectedSection = section ? section.sections[sectionStep - 1] : null;

  if (isPreview) {
    selectedSection = null;
    section = null;
    current = pageData;
  }

  const [ruleSet, setSelectedRuleSet] = useState([]);

  var id = Number(queryString.parse(window.location.search)?.id);
  var selectedStep = Number(queryString.parse(window.location.search)?.step_id);

  useEffect(() => {
    if (isPreview) {
      current = pageData;
      setSelectedRuleSet(pageData?.data?.rules);
      setSection(false);
    }

    setCurrentRuleReq({
      rule: 0,
      step: 0,
    });
    
    /*
    if (selectedStep) {
      if (window != undefined)
        window.setTimeout(function () {
          setSectionStep(selectedStep);
        }, 500);
    }
    

    console.log('debug selectedStep',selectedStep);
    */

  }, []);

  useMemo(() => {
    
    if (selectedStep) {
      setSectionStep(selectedStep);
    }
    
    return setCurrentRuleReq({
      rule: section ? section?.progressId : null,
      step: sectionStep ? sectionStep : 1,
      section: selectedSection,
    });
  }, [section, sectionStep]);

  useEffect(() => {
    if (!isNil(id) && !isNil(ruleSet)) {
      

      var indexForCurrentProgress = 0;

      for(var i = 0; i < ruleSet.length; i++)
      {
          if(ruleSet[i]?.id == progressData?.section)
             indexForCurrentProgress = i;
      }

      //check if given id is further than progress
      //console.log('currentLastProgress',indexForCurrentProgress);
      if(indexForCurrentProgress >= id)
         setSection(ruleSet[id]);

    }
  }, [id, ruleSet]);

  useEffect(() => {
    if (induction) {
      const selectInduction = induction?.data?.pages
        .find((item) => item?.type === "rules")
        ?.data?.rules.map((rule, i) => {
          return { ...rule, progressId: rule?.id ? rule?.id : index + 1 + i + 1 }; //set id of the rule if it's available
        });

      setSelectedRuleSet(selectInduction);
    }
  }, [induction]);

  //added the case that if it's not completed, include the current item as well
  const filteredList = ruleSet?.filter(
    (item) => progressData?.progress === 100 ? item?.progressId > progressData?.section : item?.progressId >= progressData?.section
  );

  useEffect(() => {
    if (!isPreview) {
      getProgress({ induction_id: induction?.id });
      setSection(false);
      saveProgress(induction, history?.location?.pathname);
    }
  }, []);

  return (
    <React.Fragment>
      <div className={classes.InductionContainer}>
        {isNil(section?.title) && (
          <Heading
            title={section ? section?.title : current?.title}
            titleId={
              isPreview
                ? current?.title_id
                : section
                ? section?.title
                : current?.title_id
            }
            description={
              section ? selectedSection?.title : current?.data?.subtitle
            }
            descriptionId={
              isPreview
                ? current?.data?.subtitle
                : section
                ? selectedSection.title_id
                : current?.data?.subtitle_id
            }
          />
        )}
        {!isNil(section) && section && (
          <Section
            data={section}
            step={sectionStep}
            setStep={setSectionStep}
            setSection={setSection}
            ruleSet={ruleSet}
            induction={induction}
            history={history}
          />
        )}
        {!section && (
          <div className={classes.InductionWrapper}>
            {progressData &&
              ruleSet &&
              ruleSet.map((item, i) => (
                <Card
                  isLocked={
                    progressData?.section >= item.progressId ||
                    i === 0 ||
                    (progressData?.progress === 100 &&
                      progressData.section + 1 === item.progressId)
                  }
                  onClick={async () => {
                    if (
                      progressData?.section >= item.progressId ||
                      i === 0 ||
                      (progressData?.progress === 100 &&
                        progressData.section + 1 === item.progressId)
                    ) 
                    {
                      
                      setSection(item); //this is actually the rule
                      await delay(50);

                      //decide what progress to set
                      var progressToSet = 0;

                      if(progressData?.section == item?.progressId)
                      {
                         if(progressData?.progress === 100)
                            progressToSet = 0; //user already completed. Start from scratch
                          else
                            progressToSet = progressData?.progress ? progressData?.progress : 0;
                      }

                      setProgressReq({
                        section: item?.progressId,
                        progress: progressToSet,
                        induction_id: induction?.id,
                      });
                      
                      
                      //determine which page to open from progress
                      var stepToOpen = parseInt( progressToSet / (100 / item.sections.length) ) + 1;

                      history.push('/rules?id=' + i + '&step_id=' + stepToOpen, { shallow: true });
                      setSectionStep(stepToOpen);
                      //window.location = '/rules?id=' + i + '&step_id=' + stepToOpen;
                    }
                  }}
                  id={item.id}
                  icon={item.icon}
                  iconType={item.iconType}
                  iconColor={item.iconColor}
                  iconBackground={item.iconBackground}
                  progressColor={item.progressColor}
                  progressEmptyColor={item.progressEmptyColor}
                  background={item.background}
                  ctaColor={item.ctaColor}
                  image={item.image}
                  key={item.id}
                  title={item.title}
                  titleId={item.title_id}
                  progress={
                    progressData?.section > item?.progressId
                      ? "100%"
                      : progressData?.section === item?.progressId
                      ? `${progressData?.progress}%`
                      : "0%"
                  }
                  color={item.color}
                  completed={false}
                  {...item}
                />
              ))}
          </div>
        )}
      </div>
      
      {!section && filteredList && !isPreview && (
        <Footer>
          <Button
            subText={
              !isNil(filteredList) && !isEmpty(filteredList)
                ? filteredList[0]?.title
                : next?.menu_name
            }
            type={"submit"}
            id="apply-button"
            text="Continue"
            theme="blue"
            formatID="continue.button"
            onClick={() => {
              if (isEmpty(filteredList)) {
                history.push(next?.url);
              } else {
                setSection(filteredList[0]);

                //await delay(50);

                //decide what progress to set
                var progressToSet = 0;

                if(progressData?.section == filteredList[0]?.progressId)
                {
                   if(progressData?.progress === 100)
                      progressToSet = 0; //user already completed. Start from scratch
                    else
                      progressToSet = progressData?.progress ? progressData?.progress : 0;
                }

                setProgressReq({
                  section: filteredList[0]?.progressId,
                  progress: progressToSet,
                  induction_id: induction?.id,
                });
                
                
                //determine which page to open from progress
                var stepToOpen = parseInt( progressToSet / (100 / filteredList[0]?.sections.length) ) + 1;

                //history.push('/rules?id=' + i + '&step_id=' + stepToOpen, { shallow: true });
                setSectionStep(stepToOpen);

                /*
                setProgressReq({
                  section: filteredList[0]?.progressId,
                  progress: 0,
                  induction_id: induction?.id,
                });
                */
                
              }
            }}
          />
        </Footer>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  assesmentTryId: makeSelectAssesmentTryId(),
  assesmentData: makeSelectAssesment(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  progressData: makeSelectProgress(),
  selectedStepId: makeSelectStep(),
  result: makeSelectAssesmentResult(),
  induction: makeSelectSelectedInduction(),
  section: makeSelectSection(),
  sectionStep: makeSelectSectionStep(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAssesment: (val) => dispatch(getAssesmentRequest(val)),
    setAssesmentQuestion: (val) => dispatch(setAssesmentRequest(val)),
    createNewAssesment: (val) => dispatch(createAssesmentRequest(val)),
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgressReq: (val) => dispatch(setProgressRequest(val)),
    resetCurrentResult: (val) => dispatch(resetAssesmentResult(val)),
    setCurrentSectionReq: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
    setCurrentRuleReq: (val) => dispatch(setCurrentRule(val)),
    setSection: (val) => dispatch(setSectionReq({ value: val })),
    setSectionStep: (val) => dispatch(setSectionStepReq({ value: val })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Induction);
